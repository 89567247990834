function AboutForter() {
  return (
    <div className="block" id="forter">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ cat</span> Forter
        </div>
        <div className="description">
          Our trust engine provides decisions on every critical interaction on
          the merchant’s website or mobile app. Our customers trigger API calls
          to get real-time (&lt;500ms) decisions for critical questions such as
          "Should we approve this transaction?", “Should we trigger MFA for this
          login attempt?”, “Should we approve this signup attempt?” and more.
        </div>
        <div className="description">
          We solve the earliest issue of the web - not knowing who is behind the
          screen. Fraudsters and abusers often change their attack vectors when
          caught, making our life more interesting. There is never “done” in
          this business (tip: ask us about our favorite fraud story in your
          interview with us).
        </div>
      </div>
    </div>
  );
}

export default AboutForter;
