function ForterStatistics() {
  return (
    <div className="block" id="statistics">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ extract</span> interesting numbers
        </div>
        <div className="description">
          Everyday at Forter is a busy day. Here are some numbers to allow you
          to grasp our scale.
        </div>
      </div>
      <div className="statistics">
        <div className="stats" data-aos="fade-in">
          <div className="stats-title">>50,000,000 a day</div>
          <div className="stats-description">
            Decisions provided in real-time
          </div>
        </div>

        <div className="stats" data-aos="fade-in" data-aos-delay="50">
          <div className="stats-title">>$250B in 2020</div>
          <div className="stats-description">
            Volume processed and growing rapidly
          </div>
        </div>

        <div className="stats" data-aos="fade-in" data-aos-delay="100">
          <div className="stats-title">>100K per second</div>
          <div className="stats-description">
            Events processed from mobile & web
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForterStatistics;
