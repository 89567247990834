import weight from "../../assets/images/values/weight.png";
import book from "../../assets/images/values/book.png";
import abc from "../../assets/images/values/abc.png";
import baloons from "../../assets/images/values/baloons.png";
import heart from "../../assets/images/values/heart.png";
import group from "../../assets/images/values/group.png";

const values = [
  {
    title: "SUSTAINABILITY",
    description:
      "So that you can invest in your personal growth for years to come, rather than a short sprint to level up and burnout.",
    img: weight
  },
  {
    title: "DEEP MASTERY",
    description:
      "So that you can seek for consistent yet incremental improvement in areas you want to become an expert at.",
    img: book
  },
  {
    title: "PLAYFUL CREATIVITY",
    description:
      "So that you can seek to constantly try new things even if they’re tiny. Doing things for the first time allows us to test new areas for growth, and enhance our capacity for failures.",
    img: abc,
    marginBottom: "16px"
  },
  {
    title: "RAW HAPPINESS",
    description:
      "So that you’ll feel excited arriving to work and be in a safe and supportive environment. Positivity, empathy, and happiness are skills worth learning.",
    img: baloons
  },
  {
    title: "AUTHENTICITY",
    description:
      "So you could feel that what you do has a direct impact on the business while acting with what feels right to us as individuals. By being who we are, we can escape competition doing what we do best, and what we love, for the long run.",
    img: heart
  },
  {
    title: "TEAMWORK AND COLLABORATION",
    description:
      "So that you'll know someone else has your back. We celebrate differences and synergies & push for those.",
    img: group,
    marginBottom: "10px"
  }
];

function ForterValues() {
  return (
    <div className="block" id="values">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ more</span> engineering values
        </div>
        <div className="description">
          These core values are fundamental to us as individuals and as a team:
        </div>
      </div>
      <div className="values">
        {values.map((value, index) => (
          <div key={`value-${index}`} className="value" data-aos="fade-in">
            <div key={`value-img-${index}`} className="value-icon">
              <img
                style={{
                  marginBottom: value.marginBottom ? value.marginBottom : ""
                }}
                src={value.img}
              />
            </div>
            <div key={`value-title-${index}`} className="value-title">
              {value.title}
            </div>
            <div
              key={`value-description${index}`}
              className="value-description"
            >
              {value.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ForterValues;
