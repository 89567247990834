import anna from "../../assets/images/users/anna.jpg";
import oren from "../../assets/images/users/oren.jpg";
import ori from "../../assets/images/users/ori.jpg";
import or from "../../assets/images/users/or.png";
import itai from "../../assets/images/users/itai.jpg";
import david from "../../assets/images/users/merchant-solutions/david.jpg";

import "./forter-employees.css";

const content = [
  {
    title: "INCIDENT MGMT: HOW TO HIRE PEOPLE WITH A KNACK FOR IT",
    author: "Anna Tsibulskaya",
    img: anna,
    time: "1 Year at Forter",
    storyDescription:
      "How much time do you spend thinking about soft skills when hiring engineers? This post will help you look for the right talent.",
    callToActionLabel: "Read Article",
    callToActionLink:
      "https://medium.com/@annatsibulskaya/incident-management-how-to-hire-people-with-a-knack-for-it-c69d2ec6524e"
  },
  {
    title: "VIM FOR DEVELOPERS (PART 1 OF 5)",
    author: "David Ondrich",
    img: david,
    time: "3 Years at Forter",
    storyDescription:
      "This post will walk you through the very most basic functionalities of Vim. These are the fundamentals and are important to master before moving onto more complicated use-cases.",
    callToAction: "Read Article",
    callToActionLink:
      "https://medium.com/analytics-vidhya/vim-for-developers-part-1-the-basics-663619ca122a?sk=ce1a035543f8c9f9a245c6c99be13a81"
  },
  {
    title: "SOFTWARE LEAD WEEKLY",
    author: "Oren Ellenbogen",
    img: oren,
    time: "7 Years at Forter",
    storyDescription:
      "For 7 years straight, Oren Ellenbogen, Forter's VP of Engineering, releases a weekly summary of his favorite blog posts and media",
    callToActionLabel: "Visit Website",
    callToActionLink: "https://softwareleadweekly.com/"
  },
  {
    title: "SECURITY 101 FOR SAAS STARTUPS",
    author: "Itai Friendinger",
    img: itai,
    time: "7 Years at Forter",
    storyDescription:
      "So you are working at a startup, and you have been wondering at what point should you start looking into security considerations and compliance? Read our advice!",
    callToActionLabel: "View Github",
    callToActionLink:
      "https://github.com/forter/security-101-for-saas-startups/"
  },
  {
    title: "DO YOU TRUST YOUR FRONT-END TESTS AS MUCH AS WE DO?",
    author: "Ori Weingart",
    img: ori,
    time: "4 Years at Forter",
    storyDescription:
      "The Engineering Team at Forter requires incredible agility – while we don’t have a QA team, we want to deploy any change to production immediately without sacrificing quality. To support this mindset, we began searching for an end-to-end web UI testing framework.",
    callToAction: "Read Article",
    callToActionLink:
      "https://tech.forter.com/do-you-trust-your-front-end-tests-as-much-as-we-do/"
  },
  {
    title: "HOW WE WROTE A CRASH-FREE MOBILE SDK",
    author: "Or Polaczek",
    img: or,
    time: "5 Years at Forter",
    storyDescription:
      "This talk tells our developer-story from when we created our mobile SDK, covering the different challenges we faced and how we got from 10,000 devices to 1 billion devices.",
    callToAction: "View Talk",
    callToActionLink: "https://www.youtube.com/watch?v=_99-ya2ZtbE"
  }
];

function ForterEmployees() {
  return (
    <div className="block" id="employees">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ uniq</span> employees
        </div>
        <div className="description">
          Our talented employees take an active part in the engineering
          community. From visiting and giving lectures in meet-ups across the
          globe, to hosting ones in office. We also like to make fun so we built{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://softwarearchitectureaddict.com"
            target="_blank"
          >
            softwareArchitectureAddict.com
          </a>{" "}
          and shared a few more stories:
        </div>
      </div>
      <div className="employee-contributions">
        <div className="marquee">
          <div className="marquee--inner">
            <span key="marquee-1" className="marquee-span">
              {content.map((item, index) => (
                <div
                  onClick={() => openInNewTab(item.callToActionLink)}
                  key={`orb-1-${  index}`}
                  className="orb"
                >
                  <div key={`title-1-${  index}`} className="story-title">
                    {item.title}
                  </div>
                  <div key={`story-1-${  index}`} className="story-description">
                    {item.storyDescription}
                  </div>
                  <img
                    key={`pic-1-${  index}`}
                    className="story-picture"
                    src={item.img}
                  />
                  <div key={`author-1-${  index}`} className="story-author">
                    {item.author}
                  </div>
                  <div key={`time-1-${  index}`} className="story-time">
                    {item.time}
                  </div>
                </div>
              ))}
            </span>
            <span key="marquee-2" className="marquee-span">
              {content.map((item, index) => (
                <div
                  onClick={() => openInNewTab(item.callToActionLink)}
                  key={`orb-2-${  index}`}
                  className="orb"
                >
                  <div key={`title-2-${  index}`} className="story-title">
                    {item.title}
                  </div>
                  <div key={`story-2-${  index}`} className="story-description">
                    {item.storyDescription}
                  </div>
                  <img
                    key={`pic-2-${  index}`}
                    className="story-picture"
                    src={item.img}
                  />
                  <div key={`author-2-${  index}`} className="story-author">
                    {item.author}
                  </div>
                  <div key={`time-2-${  index}`} className="story-time">
                    {item.time}
                  </div>
                </div>
              ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function openInNewTab(url) {
  console.log(url);
  window.open(url, "_blank");
}

export default ForterEmployees;
