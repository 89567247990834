import fingerprint from "../../assets/images/fingerprint.png";
import "./join-us.css";
import { Component } from "react";
import * as THREE from "three";

export class JoinUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      viewedJobs: [],
      counterIndex: 1,
      counterTotal: 0,
      itemsInPage: [0, 1, 2, 3, 4, 5],
      duration: 20000.0,
      isPaused: false
    };

    setInterval(
      this.timer.bind(this),
      this.state.duration / this.state.itemsInPage.length - 450
    );
  }

  timer() {
    if (!this.state.isPaused) {
      this.updateItemInLocation();
      this.setState({
        counterIndex:
          this.state.itemsInPage.length > this.state.counterIndex
            ? this.state.counterIndex + 1
            : 0,
        counterTotal: this.state.counterTotal + 1
      });
    }
  }

  updateItemInLocation() {
    if (this.state.viewedJobs.length) {
      this.setState({
        viewedJobs: this.state.viewedJobs.map((job, i) =>
          i === this.state.counterIndex ? this.getItem(i) : job
        )
      });
    }
  }

  getItem(index) {
    let actualItemLocation =
      this.state.counterTotal * this.state.itemsInPage.length + index;
    while (actualItemLocation > this.state.jobs.length - 1) {
      actualItemLocation -= this.state.jobs.length;
    }
    return this.state.jobs[actualItemLocation];
  }

  async componentDidMount() {
    await this.fetchOpenPositions();
    class Stage {
      constructor() {
        this.renderParam = {
          width: window.innerWidth,
          height: window.innerHeight
        };

        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.geometry = null;
        this.material = null;
        this.mesh = null;

        this.isInitialized = false;
      }

      init() {
        this._setScene();
        this._setRender();
        this._setCamera();
        this._setFog();

        this.isInitialized = true;
      }

      _setScene() {
        this.scene = new THREE.Scene();
      }

      _setRender() {
        this.renderer = new THREE.WebGLRenderer({
          canvas: document.getElementById("webgl-canvas"),
          alpha: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.renderParam.width, this.renderParam.height);
      }

      _setCamera() {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (!this.isInitialized) {
          this.camera = new THREE.PerspectiveCamera(
            70,
            this.renderParam.width / this.renderParam.height
          );
        }

        this.camera.aspect = windowWidth / windowHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(windowWidth, windowHeight);
      }

      _setFog() {
        this.scene.fog = new THREE.Fog(0x000000, 50, 2000);
      }

      _render() {
        let rot = 0;
        rot += 0.5;
        const radian = (rot * Math.PI) / 180;
        this.camera.position.x = 1000 * Math.cos(radian);
        this.camera.position.z = 1000 * Math.sin(radian);
        this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        this.renderer.render(this.scene, this.camera);
      }

      onResize() {
        this._setCamera();
      }

      onRaf() {
        this._render();
      }
    }

    class Mesh {
      constructor(stage) {
        this.stage = stage;

        this.mesh = null;
      }

      init() {
        this._setMesh();
      }

      _setMesh() {
        const geometry = new THREE.Geometry();
        for (let i = 0; i < 10000; i++) {
          const star = new THREE.Vector3();
          star.x = THREE.Math.randFloatSpread(2000);
          star.y = THREE.Math.randFloatSpread(2000);
          star.z = THREE.Math.randFloatSpread(2000);

          geometry.vertices.push(star);
        }
        const material = new THREE.PointsMaterial({
          color: 0xffffff
        });

        this.mesh = new THREE.Points(geometry, material);

        this.stage.scene.add(this.mesh);
      }

      _render() {
        this.mesh.rotation.y += 0.0005;
      }

      onRaf() {
        this._render();
      }
    }

    (() => {
      const stage = new Stage();

      stage.init();

      const mesh = new Mesh(stage);

      mesh.init();

      window.addEventListener("resize", () => {
        stage.onResize();
      });

      const _raf = () => {
        window.requestAnimationFrame(() => {
          stage.onRaf();
          mesh.onRaf();

          _raf();
        });
      };

      _raf();
    })();
  }

  duplicateArray(arr, times) {
    let dup = [];
    for (let i = 0; i < times; i++) {
      dup = dup.concat(arr);
    }
    return dup;
  }

  async fetchOpenPositions() {
    const res = await fetch(
      "https://boards-api.greenhouse.io/v1/boards/forter/jobs"
    );
    const { jobs } = await res.json();
    const longJobs = this.duplicateArray(jobs, 3);
    await this.setState({
      jobs: longJobs.filter(job => job.title.toLowerCase().includes("engineer")),
      viewedJobs: longJobs
        .filter(job => job.title.toLowerCase().includes("engineer"))
        .filter((job, i) => i < this.state.itemsInPage.length)
    });
  }

  render() {
    return (
      <div className="block" id="introduction">
        <canvas id="webgl-canvas" />
        <div id="join-us-content">
          <div className="logo">
            <img src={fingerprint} /> Forter Engineering Presents...
          </div>
          <div className="title introduction">
            We build systems to protect eCommerce from fraud and abuse. We take pride in building the foundations for a
            safer Internet at massive scale.
          </div>
          <div className="open-positions">
            <a href="https://boards.greenhouse.io/forter" target="_blank">
              <button id="apply-button">JOIN OUR STAR TEAM</button>
            </a>
          </div>
          <div
            onMouseOver={() => this.setState({ isPaused: true })}
            onMouseLeave={() => this.setState({ isPaused: false })}
            className="floating-jobs"
          >
            {this.state.viewedJobs.length
              ? this.state.itemsInPage.map(i => (
                  <a
                    className="floating-job"
                    key={`opening-${  i}`}
                    style={{
                      animationDelay: `${i * 3.2}s`,
                      top: i % 2 === 0 ? "60px" : "-60px",
                      animationDuration: `${this.state.duration  }ms`
                    }}
                    target="_blank"
                    href={this.state.viewedJobs[i].absolute_url}
                  >
                    {this.state.viewedJobs[i].title}
                  </a>
                ))
              : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default JoinUs;
