import composition3 from "../assets/images/composition3.png";
import david from "../assets/images/users/merchant-solutions/david-ondrich.png";
import cloud from "../assets/images/users/precision-engineering/interests/cloud.svg";
import region from "../assets/images/users/devops/interests/region.svg";
import sla from "../assets/images/users/precision-engineering/interests/sla.svg";
import homework from "../assets/images/interview/homework.svg";
import interview from "../assets/images/interview/interview.svg";
import executive from "../assets/images/interview/executive.svg";
import hr from "../assets/images/interview/hr.svg";

const merchantSolutionsEngineering = {
    name: "Merchant Solutions Engineering",
    img: composition3,
    colors: {
        main: {
            background: "linear-gradient(270deg, #9681c2 0%, #7f56d6 100%)",
            dark: true,
            primaryText: "#3e2671"
        },
        motivationBackground: "#f2ebff",
        challengesBackground: "#d8d2e6",
        process: {
            light: "#d8d2e6",
            dark: "#5200ff"
        },
        submit: {
            background: "#f2ebff",
            button: "#c6a7ff"
        }
    },
    about:
        "We build and support the tooling to ensure a frictionless onboarding experience for our merchants. We also support our third-party eCommerce extensions which are seeing interest exploding as platforms like Shopify and Magento continue to expand.",
    people: [
        {
            name: "David Ondrich",
            role: "Merchant Solutions Engineering Manager",
            interest:
                "I like to stay active (mostly to make up for trying as many restaurants, here in New York City, as I can). Previously I lived in Tel Aviv for 3 years, where I started working for Forter. I'm excited to be building Forter's first engineering team outside of Israel!",
            picture: david
        }
    ],
    interested: {
        description:
            "Customer experience starts from the moment a merchant starts the onbaording process. We're committed to building the best tools possible to make this process seamless, and automated as much as possible.",
        items: [
            {
                img: cloud,
                title: "Multi Cloud",
                description:
                    "Making anything work smoothly throughout every cloud needed."
            },
            {
                img: region,
                title: "Multi Region",
                description:
                    "Working hard to keep a 100% uptime with always having backup plan, keep data in various regions as well as improve SLAs."
            },
            {
                img: sla,
                title: "Cutting-edge Performances",
                description:
                    "Working hard to improve SLAs, even if it means miliseconds."
            }
        ]
    },
    challenges:
        "Onboarding the massive enterprise merchants that Forter works with is no simple task. We're constantly improving our tools and processes, as well as developing new ones, to provide the best service for our clients.",
    interviewProcess: [
        {
            img: homework,
            name: "Homework",
            nameDescription: "Just to prove us you're worthy",
            description:
                "We'll ask you to prepare homework over a few days with new technologies, just to see how you're holding up. They're not very hard, as we want everyone to express themselves and see which problem-solving directions are taken"
        },
        {
            img: interview,
            name: "Technical Interview",
            nameDescription: "You'll get to see our beautiful offices",
            description:
                "We'll ask you to alter some parts of your homework to create a new feature. We know it's very stressful, so we're not asking anything over-the-top :)"
        },
        {
            img: executive,
            name: "Executive Interview",
            nameDescription: "Almost there...",
            description:
                "One of our executives (VP R&D and sometimes CTO) will ask you questions to see your cultural fit and technical domain(s) across teams."
        },
        {
            img: hr,
            name: "HR Interview",
            nameDescription: "Bingo!",
            description:
                "The HR department will ask questions regarding your history and will provide detail regarding terms and benefits."
        }
    ],
    email: {
        address: "merchant-solutions@forter.com",
        subject: "Applying to Merchant Solutions Engineering",
        body: [
            "Hey Team!",
            "I saw your team profile on forter.dev and I thought I could be a good fit.",
            "My main technologies are X, Y.",
            "In my spare time I like to do Z."
        ]
    }
};

export default merchantSolutionsEngineering;
