function ForterPictures() {
  return (
    <div className="block" id="us">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ diff</span> us others
        </div>
        <div className="description">
          From being heard, through impacting and changing, to architecture
          talks & designs. With a great culture and company-wide events, Forter
          is truly one of a kind in its field.
        </div>
      </div>
      <div className="collage">
        <a
          id="collage-1"
          data-aos="fade-in"
          data-aos-delay="20"
          href="https://www.youtube.com/watch?v=uI8NDsDouig"
          target="_blank"
        ></a>
        <a id="collage-2" data-aos="fade-in" data-aos-delay="40"></a>
        <a
          id="collage-3"
          data-aos="fade-in"
          data-aos-delay="60"
          href="https://www.youtube.com/watch?v=hdBxsvdAII4"
          target="_blank"
        ></a>
        <a
          id="collage-4"
          data-aos="fade-in"
          data-aos-delay="80"
          href="https://www.youtube.com/watch?v=nDOWldCfH6o"
          target="_blank"
        ></a>
        <a id="collage-5" data-aos="fade-in" data-aos-delay="100"></a>
        <a id="collage-6" data-aos="fade-in" data-aos-delay="120"></a>
      </div>
    </div>
  );
}

export default ForterPictures;
