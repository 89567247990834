import JoinUs from "./main-page-components/join-us";
import AboutForter from "./main-page-components/about-forter";
import ForterValues from "./main-page-components/forter-values";
import ForterPictures from "./main-page-components/forter-pictures";
import ForterEmployees from "./main-page-components/forter-employees";
import ForterStatistics from "./main-page-components/forter-statistics";
import ForterTools from "./main-page-components/forter-tools";
import ForterOffice from "./main-page-components/forter-office";
import ForterBenefits from "./main-page-components/forter-benefits";

function MainPage() {
  return (
    <>
      <div className="container">
        <JoinUs></JoinUs>
        <ForterValues></ForterValues>
        <ForterEmployees></ForterEmployees>
        <ForterTools></ForterTools>
        <ForterBenefits></ForterBenefits>
        <ForterOffice></ForterOffice>
        <ForterPictures></ForterPictures>
        <ForterStatistics></ForterStatistics>
      </div>
    </>
  );
}

export default MainPage;
