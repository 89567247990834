import muscle from "../../assets/images/benefits/muscle.png";
import money from "../../assets/images/benefits/money.png";
import house from "../../assets/images/benefits/house.png";
import vacation from "../../assets/images/benefits/vacation.png";
import dog from "../../assets/images/benefits/dog.png";
import team from "../../assets/images/benefits/team.png";
import options from "../../assets/images/benefits/options.png";
import sleep from "../../assets/images/benefits/sleep.png";
import pot from "../../assets/images/benefits/pot.png";

const benefits = [
  {
    title: "STRONG BODY, STRONG MIND",
    description:
      "Forter cares about your health and hosts weekly pilates courses, as well as annual marathon runs and other sport events.",
    img: muscle
  },
  {
    title: "DOLLARS & MORE",
    description:
      "You'll get competitive pay, advancement opportunities, career guidance, and referral bonuses if we hire someone you recommend.",
    img: money
  },
  {
    title: "WORK FROM WHEREVER WORKS FOR YOU",
    description:
      "With Forterians across the globe and a flexible work from home policy (even pre-Covid times), we support a work/life balance that keeps you centered.",
    img: house
  },
  {
    title: "TAKE TIME OFF TO REST & RECHARGE",
    description:
      "We provide generous paid time off, so you can follow your passions, visit family, or just relax.",
    img: vacation
  },
  {
    title: "DOGGO IS MAN’S BEST FRIEND",
    description:
      "Cats may rule the Internet, but dogs rule the office. Bring your best friend to work or enjoy unconditional love from our many office dogs.",
    img: dog
  },
  {
    title: "A TEAM THAT PLAYS TOGETHER, STAYS TOGETHER",
    description:
      "We hold monthly events for all employees, regardless of location, and fly everyone together for special events. We put emphasis on diverse teams - from disadvantaged communities all the way to LGBT+",
    img: team
  },
  {
    title: "SPLIT ATTENTION, DONE RIGHT",
    description:
      "Forter appreciates how hard it is to work and sometimes even manage a household during Covid times, so it gives everyone a Sunday off every other week, even in these stressful times.",
    img: sleep
  },
  {
    title: "OPTIONS, OPTIONS, OPTIONS",
    description:
      "Despite being a unicorn and preparing for an IPO, we still provide generous stock options for all of our R&D members.",
    img: options
  },
  {
    title: "SAVING, ONE COIN AT A TIME",
    description:
      "We care about your future. That's why we fully fund insurance agents, so they could tell you for reals what's the best for you, as well as putting money aside in a private investment fund, just for you.",
    img: pot
  }
];

function ForterBenefits() {
  return (
    <div className="block" id="benefits">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ ls</span> benefits
        </div>
        <div className="description">
          Apart from being surrounded by awesome people, we also offer:
        </div>
      </div>

      <div className="benefits">
        {benefits.map((benefit, index) => (
          <div key={`benefit-${index}`} className="benefit" data-aos="fade-in">
            <div key={`benefit-img-${index}`} className="benefit-icon">
              <img src={benefit.img} />
            </div>
            <div key={`benefit-title-${index}`} className="benefit-title">
              {benefit.title}
            </div>
            <div
              key={`benefit-description${index}`}
              className="benefit-description"
            >
              {benefit.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ForterBenefits;
