import productEngineering from "./productEngineering";
import precisionEngineering from "./precisionEngineering";
// import foundationEngineering from "./foundationEngineering";
// import paymentEngineering from "./paymentEngineering";
import devops from "./paymentEngineering";
import merchantSolutionsEngineering from "./merchantSolutionsEngineering";


export default {
  "product-engineering": productEngineering,
  "precision-engineering": precisionEngineering,
  "infrasec-devops": devops,
  // "foundation-engineering": foundationEngineering,
  // "payment-engineering": paymentEngineering,
  "merchant-solutions-engineering": merchantSolutionsEngineering
};
