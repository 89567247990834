import React, { Component } from "react";

import javascript from "../../assets/images/tech/javascript.png";
import node from "../../assets/images/tech/nodejs.png";
import python from "../../assets/images/tech/python.png";
import spark from "../../assets/images/tech/spark.png";
import kotlin from "../../assets/images/tech/kotlin.png";
import java from "../../assets/images/tech/java.png";
import redis from "../../assets/images/tech/redis.png";
import mysql from "../../assets/images/tech/mysql.png";
import elastic from "../../assets/images/tech/elasticsearch.png";
import logstash from "../../assets/images/tech/logstash.png";
import kibana from "../../assets/images/tech/kibana.png";
import jenkins from "../../assets/images/tech/jenkins.png";
import chef from "../../assets/images/tech/chef.png";
import docker from "../../assets/images/tech/docker.png";
import terraform from "../../assets/images/tech/terraform.png";
import snowflake from "../../assets/images/tech/snowflake.png";
import couchbase from "../../assets/images/tech/couchbase.png";
import graphql from "../../assets/images/tech/graphql.png";
import react from "../../assets/images/tech/react.png";
import vue from "../../assets/images/tech/vue.png";
import webcomponents from "../../assets/images/tech/webcomponents.png";
import webpack from "../../assets/images/tech/webpack.png";
import rollup from "../../assets/images/tech/rollup.png";
import storybook from "../../assets/images/tech/storybook.png";
import ElasticTabs from "../elastic-tabs/elastic-tabs";

const tools = {
  javascript: {
    name: "JavaScript",
    img: javascript
  },
  node: {
    name: "NodeJS",
    img: node
  },
  python: {
    name: "Python 3.9",
    img: python
  },
  spark: {
    name: "Spark",
    img: spark
  },
  kotlin: {
    name: "Kotlin",
    img: kotlin
  },
  java: {
    name: "Java 15",
    img: java
  },
  redis: {
    name: "redis",
    img: redis
  },
  mysql: {
    name: "MySQL",
    img: mysql
  },
  elasticsearch: {
    name: "Elasticsearch",
    img: elastic
  },
  logstash: {
    name: "Logstash",
    img: logstash
  },
  kibana: {
    name: "Kibana",
    img: kibana
  },
  jenkins: {
    name: "Jenkins",
    img: jenkins
  },
  chef: {
    name: "Chef Infra Client",
    img: chef
  },
  docker: {
    name: "Docker",
    img: docker
  },
  terraform: {
    name: "Terraform",
    img: terraform
  },
  snowflake: {
    name: "Snowflake",
    img: snowflake
  },
  couchbase: {
    name: "Couchbase",
    img: couchbase
  },
  graphql: {
    name: "GraphQL",
    img: graphql
  },
  react: {
    name: "React",
    img: react
  },
  vue: {
    name: "Vue.js",
    img: vue
  },
  webcomponents: {
    name: "Web Components",
    img: webcomponents
  },
  webpack: {
    name: "Webpack",
    img: webpack
  },
  rollup: {
    name: "Rollup",
    img: rollup
  },
  storybook: {
    name: "StoryBook",
    img: storybook
  }
};

const TEAM_NAMES = {
  PRODUCT_ENGINEERING: "Product Engineering",
  INFRASEC: "InfraSec & Devops",
  PRECISION: "Precision",
  DATA_PLATFORM: "Data Platforms",
  FOUNDATIONS: "Foundations",
  RESEARCH: "Research",
  PAYMENT: "Payments",
  MERCHANT_SOLUTIONS: "Merchant Solutions Engineering"
};

const teams = [
  {
    name: TEAM_NAMES.PRODUCT_ENGINEERING,
    link: "#/team/product-engineering",
    tools: [
      tools.javascript,
      tools.node,
      tools.python,
      tools.redis,
      tools.mysql,
      tools.elasticsearch,
      tools.kibana,
      tools.jenkins,
      tools.chef,
      tools.graphql,
      tools.react,
      tools.webpack,
      tools.rollup,
      tools.storybook
    ]
  },
  {
    name: TEAM_NAMES.INFRASEC,
    link: "#/team/infrasec-devops",
    tools: [
      tools.python,
      tools.java,
      tools.redis,
      tools.mysql,
      tools.elasticsearch,
      tools.logstash,
      tools.kibana,
      tools.jenkins,
      tools.chef,
      tools.docker,
      tools.terraform,
      tools.snowflake,
      tools.couchbase
    ]
  },
  {
    name: TEAM_NAMES.PRECISION,
    link: "#/team/precision-engineering",
    tools: [
      tools.python,
      tools.elasticsearch,
      tools.kibana,
      tools.jenkins,
      tools.chef,
      tools.vue,
      tools.docker
    ]
  },
  {
    name: TEAM_NAMES.DATA_PLATFORM,
    tools: [
      tools.python,
      tools.elasticsearch,
      tools.kibana,
      tools.jenkins,
      tools.chef,
      tools.docker,
      tools.snowflake
    ]
  },
  {
    name: TEAM_NAMES.FOUNDATIONS,
    // link: '#/team/offering-engineering',
    tools: [
      tools.javascript,
      tools.node,
      tools.python,
      tools.redis,
      tools.mysql,
      tools.elasticsearch,
      tools.kibana,
      tools.jenkins,
      tools.chef,
      tools.graphql,
      tools.react
    ]
  },
  {
    name: TEAM_NAMES.MERCHANT_SOLUTIONS,
    // link: '#/team/merchants-solutions-engineering',
    tools: [
      tools.javascript,
      tools.node,
      tools.python,
      tools.redis,
      tools.mysql,
      tools.elasticsearch,
      tools.kibana,
      tools.jenkins,
      tools.chef,
      tools.graphql,
      tools.react,
      tools.docker,
      tools.webcomponents
    ]
  }
  // {
  //     name: TEAM_NAMES.PAYMENT,
  //     // link: '#/team/payment-engineering',
  //     tools: [tools.javascript, tools.node, tools.python, tools.redis, tools.mysql, tools.elasticsearch, tools.kibana, tools.jenkins, tools.chef, tools.graphql, tools.react]
  // },
  // {
  //     name: TEAM_NAMES.RESEARCH,
  //     tools: [tools.javascript, tools.node, tools.redis, tools.logstash, tools.elasticsearch, tools.kibana, tools.react]
  // }
];

export class ForterTools extends Component {
  constructor() {
    super();
    this.state = {
      selected: ""
    };
    this.handler = this.handler.bind(this);
  }

  handler(selected) {
    this.setState({
      selected
    });
  }

  render() {
    return (
      <div className="block" id="tools">
        <div ata-aos="fade-right">
          <div className="title">
            <span className="cli-semi-hidden">$ echo</span> tools & infra
          </div>
          <div className="description">
            We’re running on top of AWS with “immutable infrastructure”. We keep
            it extremely diverse, check out for yourself:
          </div>
          <br />
        </div>

        <ElasticTabs handler={this.handler} tabs={teams}></ElasticTabs>
        <div className="tech-stack">
          {teams
            .find(item => item.name === this.state.selected)
            ?.tools.map((item, index) => (
              <div
                key={`tool-${  index}`}
                className="tech-item"
                data-aos="fade-in"
                data-aos-delay={50 * index}
              >
                <div className="circle">
                  <img src={item.img} />
                </div>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
        <a
          id="go-to-team"
          target="_blank"
          href={teams.find(item => this.state.selected === item.name)?.link}
          className={
            teams.find(item => this.state.selected === item.name)?.link
              ? "visible"
              : "hidden"
          }
        >
          Check out {this.state.selected} team!
        </a>
      </div>
    );
  }
}

export default ForterTools;
