import "./team-page.css";

import teams from "../teams/index";
import { Component } from "react";

export class TeamPage extends Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    this.setState({ id });
  }

  render() {
    if (!this.state?.id) {
      return <></>;
    }

    const team = teams[this.state.id];
    if (!team) {
      return <div>Oops! Team not found</div>;
    }

    return (
      <>
        <div
          id="team-page"
          className={`team-page ${team?.colors?.main?.dark ? "dark" : ""}`}
        >
          <div
            id="team-introduction"
            style={{ background: team?.colors?.main?.background }}
          >
            <div className="left">
              <h1 id="team-description" data-aos="fade-right">
                Introducing all things web, meet...
              </h1>
              <h1 id="team-name" data-aos="fade-right" data-aos-delay="150">
                <span
                  id="name"
                  style={{ color: team?.colors?.main?.primaryText }}
                >
                  {team.name}
                </span>{" "}
                <span id="engineering">Engineering</span>
              </h1>
              <h3 id="team-about" data-aos="fade-right" data-aos-delay="300">
                {team.about}
              </h3>
            </div>
            <img
              id="composition"
              data-aos="fade-left"
              data-aos-delay="450"
              src={team.img}
            />
          </div>

          <div id="people">
            <h2 data-aos="fade-right">Who Are We?</h2>
            <div id="people-list">
              {team.people.map((person, index) => (
                <div
                  key={`person-${index}`}
                  className="person"
                  data-aos="fade-in"
                  data-aos-delay={50 * index}
                >
                  <div className="name">{person.name}</div>
                  <div className="role">{person.role}</div>
                  <img className="pic" src={person.picture} />
                  <div className="interest">{person.interest}</div>
                </div>
              ))}
            </div>
          </div>

          <div
            id="interested"
            style={{ background: team?.colors?.motivationBackground }}
          >
            <h2 data-aos="fade-right">What Keeps Us Motivated</h2>
            <p data-aos="fade-in">{team.interested.description}</p>
            <span data-aos="fade-in">We appreciate if you care about:</span>
            <div className="interested-items">
              {team.interested.items.map((item, index) => (
                <div
                  data-aos="fade-in"
                  data-aos-delay={100 * index}
                  key={`interested-item-${index}`}
                  className="interested-item"
                >
                  <img className="interested-img" src={item.img} />
                  <span className="interested-title">{item.title}</span>
                  <span className="interested-description">
                    {item.description}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div
            id="challanges"
            data-aos="fade-in"
            style={{ background: team?.colors?.challengesBackground }}
          >
            <p>{team.challenges}</p>
          </div>

          <div
            id="process"
            style={{
              ["--primary-dark-color"]: team?.colors?.process?.dark,
              ["--primary-light-color"]: team?.colors?.process?.light
            }}
          >
            <h2 data-aos="fade-right">The Process</h2>
            <div id="process-stages">
              {team.interviewProcess.map((item, index, array) => (
                <div
                  key={`stage-${index}`}
                  className="stage"
                  data-aos="fade-in"
                  data-aos-delay={50 * index}
                >
                  <div
                    className={`interview-title-wrapper ${
                      index === 0
                        ? "first"
                        : array.length - 1 === index
                        ? "last"
                        : ""
                    }`}
                  >
                    <div className="interview-title">{item.name}</div>
                    <div className="interview-title-description">
                      {item.nameDescription}
                    </div>
                    <div className="interview-img-wrapper">
                      <img className="interview-img" src={item.img} />
                    </div>
                  </div>
                  <div className="interview-description">
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            id="join-us"
            style={{ background: team?.colors?.submit?.background }}
          >
            <h2 data-aos="fade-right">Join our team!</h2>
            <p data-aos="fade-in">
              We're always growing, expanding and looking for the missing piece.
              If you think we would be a great match - let us know! We'd love to
              see you aboard.
            </p>
            <div
              data-aos="fade-in"
              id="email-button"
              className={team?.colors?.submit?.dark ? "dark" : "bright"}
            >
              <a
                style={{ background: team?.colors?.submit?.button }}
                target="_blank"
                href={`mailto:${team.email.address}?subject=${
                  team.email.subject
                }&body=${team.email.body.join("%0D%0A")}`}
              >
                Mail Us Your CV!
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TeamPage; // Don’t forget to use export default!
