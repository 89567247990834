import "./elastic-tabs.css";

import React, { Component } from "react";

export class ElasticTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeElementName: "",
      left: "0px",
      width: "0px",
      height: "0px",
      top: "0px"
    };
  }

  tabClick(activeElementName, e) {
    const tabs = document.querySelectorAll("[elastictab]");
    for (const tab of tabs) {
      tab.classList.remove("active");
    }
    this.setState({
      activeElementName,
      width: e.target.clientWidth,
      left: e.target.offsetLeft - 5,
      top: e.target.offsetTop,
      height: e.target.clientHeight
    });
    this.props.handler(activeElementName);
  }

  componentDidMount() {
    try {
      if (
        this.state.activeElementName === "" &&
        this.state.left === "0px" &&
        this.state.width === "0px"
      ) {
        if (this.props.tabs.length) {
          setTimeout(function() {
            document.querySelector("[elastictab]").click();
          }, 300);
        }
      }
    } catch (e) {}
  }

  render() {
    return (
      <nav className="tabs">
        <div
          style={{
            left: this.state.left,
            width: this.state.width,
            top: this.state.top,
            height: this.state.height
          }}
          className="selector"
        />
        {this.props.tabs.map((item, index) => (
          <span
            elastictab="true"
            key={`elastictab-${  index}`}
            className={
              this.state.activeElementName === item.name ? "active" : ""
            }
            onClick={e => this.tabClick(item.name, e)}
          >
            {item.name}
          </span>
        ))}
      </nav>
    );
  }
}

export default ElasticTabs;
