import composition3 from "../assets/images/composition3.png";
import gilad2 from "../assets/images/users/precision-engineering/gilad.jpg";
import doron from "../assets/images/users/precision-engineering/doron.jpg";
import udi from "../assets/images/users/precision-engineering/udi.jpg";
import tal from "../assets/images/users/precision-engineering/tal.jpg";
import nathan from "../assets/images/users/precision-engineering/nathan.jpg";
import irit from "../assets/images/users/precision-engineering/irit.jpg";
import tom from "../assets/images/users/precision-engineering/tom.jpg";
import yacov from "../assets/images/users/precision-engineering/yacov.jpg";
import roy from "../assets/images/users/precision-engineering/roy.jpg";
import amir from "../assets/images/users/precision-engineering/amir.jpg";
import cloud from "../assets/images/users/precision-engineering/interests/cloud.svg";
import sla from "../assets/images/users/precision-engineering/interests/sla.svg";
import ml from "../assets/images/users/precision-engineering/interests/ml.svg";
import homework from "../assets/images/interview/homework.svg";
import interview from "../assets/images/interview/interview.svg";
import executive from "../assets/images/interview/executive.svg";
import hr from "../assets/images/interview/hr.svg";

const precisionEngineering = {
    name: "Precision",
    img: composition3,
    colors: {
        main: {
            background: "linear-gradient(270deg, #9681c2 0%, #7f56d6 100%)",
            dark: true,
            primaryText: "#3e2671"
        },
        motivationBackground: "#f2ebff",
        challengesBackground: "#d8d2e6",
        process: {
            light: "#d8d2e6",
            dark: "#5200ff"
        },
        submit: {
            background: "#f2ebff",
            button: "#c6a7ff"
        }
    },
    about:
        "Ensure real-time execution of transactions, events and payment decisions, providing the infrastructure for analytical and data science teams to achieve the most accurate decision within a strict SLA.",
    people: [
        {
            name: "Gilad Peleg",
            role: "Precision Engineering Manager",
            interest:
                "I lead the real-time decision engineering group. I’m a strong believer in consistency, incremental changes and daring to win. I believe that “Culture eats strategy for breakfast” and that “production is the only environment that matters”. I’m married and live with my 2 kids in Tel-Aviv. Love visiting the beach, reading books, hiking, and occasionally playing the piano.",
            picture: gilad2
        },
        {
            name: "Doron Yaacoby",
            role: "Principal Software Engineer",
            interest:
                "I love solving interesting problems, leading big projects, coding and arguing about architecture decisions :) I also love teaching, and at Forter I occasionally hold an Elasticsearch workshop for engineers.",
            picture: doron
        },
        {
            name: "Udi Luxenburg",
            role: "Staff Software Engineer",
            interest:
                "I love building and improving systems and solving hard problems. I enjoy traveling to exotic places and listening to music in my free time. I live in Tel Aviv with my wife and dog (Angie).",
            picture: udi
        },
        {
            name: "Tal Almuyal",
            role: "Senior Software Engineer",
            interest:
                "I love languages (C++, Python, Japanese, and more), and enjoy playing video games (Diablo, Nioh, HOTS, RTSs). I have two cats, Putsi and Tsuki.",
            picture: tal
        },
        {
            name: "Nathan Meisels",
            role: "Senior Software Engineer",
            interest:
                "I was born in the land of the frost and cold (Sweden) and we moved to Israel when I was 7 (I prefer the climate in Israel). I’m married and I have two kids. I have been at Forter for over 5 years and for a while I held the title of the employee that lives closest to the office. I’m also the proud creator of our Jewish Holiday check which includes the string 'yomtov'",
            picture: nathan
        },
        {
            name: "Irit Rolnik",
            role: "Senior Software Engineer",
            interest:
                "I live in Tel Aviv with my perfectly crazy dog named Pixel. What I enjoy most about what I do are the opportunities to solve problems that are not (yet) on StackOverflow :) When I'm not working, I love cooking, reading, practicing yoga and training my dog.",
            picture: irit
        },
        {
            name: "Tom Hyam",
            role: "Software Engineer",
            interest:
                "I was born and raised in the Kibbutz. A bit of a beer geek and bouldering enthusiast.",
            picture: tom
        },
        {
            name: "Yacov Buchritz",
            role: "Software Engineer",
            interest:
                "I am originally an electronics engineer who became a developer. Beside this I am a vocalist for a prog-metal band (which you never heard of - trust me), I also play guitar and bass. Gaming is another passion, whether it's board games, video games or RD&D, I'll do it all. I also have two cats, Butters and Feature and a dog named Bug.",
            picture: yacov
        },
        {
            name: "Roy Lewin",
            role: "Software Engineer",
            interest:
                "Programmer, martial artist and all-around nerd. When not working I enjoy making armour and various items using chainmail, leather and 3D printing",
            picture: roy
        },
        {
            name: "Amir Tirosh",
            role: "Code Guru",
            interest:
                "I live in Rishon LeZion, not yet joined the army. I love playing basketball and watching games in my free time. I also love doing magic tricks, especially with cards :)",
            picture: amir
        }
    ],
    interested: {
        description:
            "We really like latency challenges. Ranging from passing a transaction through complex flows and subservices using different data models, all the way to the end client who is using our merchant's product.",
        items: [
            {
                img: cloud,
                title: "Multi Cloud",
                description:
                    "Making anything work smoothly throughout every cloud needed"
            },
            {
                img: sla,
                title: "Cutting-edge Performances",
                description:
                    "Working hard to improve SLAs, even if it means miliseconds."
            },
            {
                img: ml,
                title: "Machine Learning",
                description:
                    "Providing better research tooling and better integration with model-training."
            }
        ]
    },
    challenges:
        "We are highly focused on fast performance, measuring latency at each stop the transaction passes, and consistently trying to improve the system’s overall latency while improving accuracy and precision.",
    interviewProcess: [
        {
            img: homework,
            name: "Homework",
            nameDescription: "Just to prove us you're worthy",
            description:
                "We'll ask you to prepare homework over a few days with new technologies, just to see how you're holding up. They're not very hard, as we want everyone to express themselves and see which problem-solving directions are taken"
        },
        {
            img: interview,
            name: "Technical Interview",
            nameDescription: "You'll get to see our beautiful offices",
            description:
                "We'll ask you to alter some parts of your homework to create a new feature. We know it's very stressful, so we're not asking anything over-the-top :)"
        },
        {
            img: executive,
            name: "Executive Interview",
            nameDescription: "Almost there...",
            description:
                "One of our executives (VP R&D and sometimes CTO) will ask you questions to see your cultural fit and technical domain(s) across teams."
        },
        {
            img: hr,
            name: "HR Interview",
            nameDescription: "Bingo!",
            description:
                "The HR department will ask questions regarding your history and will provide detail regarding terms and benefits."
        }
    ],
    email: {
        address: "precision-engineering@forter.com",
        subject: "Applying to Precision Engineering",
        body: [
            "Hey Team!",
            "I saw your team profile on forter.dev and I thought I could be a good fit.",
            "My main technologies are X, Y.",
            "In my spare time I like to do Z."
        ]
    }
};

export default precisionEngineering;
