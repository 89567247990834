import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 32.08001941180125, lng: 34.79581128282955 }}
    >
      <Marker position={{ lat: 32.08001941180125, lng: 34.79581128282955 }} />
    </GoogleMap>
  ))
);

function ForterOffice() {
  return (
    <div className="block" id="office">
      <div data-aos="fade-right">
        <div className="title">
          <span className="cli-semi-hidden">$ grep</span> office
        </div>
        <div className="description">
          Located in the heart of Tel-Aviv, next to the train station, light
          rail station & bus terminal, you'll have the chance to work in an
          amazing workspace, with a fun startup atmosphere.
        </div>
      </div>

      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDWQAHuctGznXCUr4eda8qaq9f2ATallbk&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div id="map" />}
        containerElement={<div id="map" />}
        mapElement={<div id="map" />}
      />
    </div>
  );
}

export default ForterOffice;
