import "./App.css";
import "./resets.css";
import "./fonts.css";
import "./colors.css";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

// PAGES
import TeamPage from "./components/team-page";
import MainPage from "./components/main-page";

// COMPONENTS
import "./components/elastic-tabs/elastic-tabs";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";

function initializeAOS() {
  AOS.init();
  AOS.init();
  AOS.init({
    disable: false,
    startEvent: "DOMContentLoaded",
    initClassName: "aos-init",
    animatedClassName: "aos-animate",
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 100,
    offset: 100,
    delay: 0,
    duration: 800,
    easing: "ease",
    once: false,
    mirror: false,
    anchorPlacement: "top-bottom"
  });
}

function App() {
  initializeAOS();
  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainPage}></Route>
            <Route path="/team/:id" component={TeamPage}></Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
