import composition from "../assets/images/composition.png";
import liron from "../assets/images/users/product-engineering/liron.png";
import ori from "../assets/images/users/product-engineering/ori.png";
import assaf from "../assets/images/users/product-engineering/assaf.png";
import harel from "../assets/images/users/product-engineering/harel.png";
import gilad from "../assets/images/users/product-engineering/gilad.png";
import yuval from "../assets/images/users/product-engineering/yuval.png";
import omer from "../assets/images/users/product-engineering/omer.png";
import yonatan from "../assets/images/users/product-engineering/yonatan.png";
import daniel from "../assets/images/users/product-engineering/daniel.png";
import roee from "../assets/images/users/product-engineering/roee.png";
import micro from "../assets/images/users/product-engineering/interests/micro.svg";
import visual from "../assets/images/users/product-engineering/interests/visual.svg";
import filter from "../assets/images/users/product-engineering/interests/filter.svg";
import phone from "../assets/images/interview/phone.svg";
import homework from "../assets/images/interview/homework.svg";
import interview from "../assets/images/interview/interview.svg";
import executive from "../assets/images/interview/executive.svg";
import hr from "../assets/images/interview/hr.svg";

const productEngineering = {
    name: "Product",
    img: composition,
    colors: {
        main: {
            background: "linear-gradient(270deg, #e2cebe 0%, #f5c299 100%)"
        }
    },
    about:
        "We're a fun full-stack (front-end focused) team oriented towards creating tools customers will love using. We own most internal and external tools and web-systems in Forter and always enjoy creating new ones from scratch. We are very sociable and always welcome new members. We're trying to have at least one quarterly team night and in the summer we're laying by the pool. Every Thursday we're having a team happy hour, too.",
    people: [
        {
            name: "Liron Goldenberg",
            role: "Product Engineering Manager",
            interest:
                "Liron enjoys traveling around, whether it's in his hometown, up far north or skiing abroad",
            picture: liron
        },
        {
            name: "Ori Weingart",
            role: "Staff Software Engineer",
            interest:
                "Ori especially likes his dog, Joy, who has slowly become an office icon, improving the atmosphere and wasting everyone's time in the best way possible - petting her",
            picture: ori
        },
        {
            name: "Assaf Milman",
            role: "Senior Software Engineer",
            interest:
                "Assaf splits his day between two of his loves - messing up with data and seeing his two little kids back at home.",
            picture: assaf
        },
        {
            name: "Harel Gendler",
            role: "Senior Software Engineer",
            interest:
                "Harel would probably spend his weekend enjoying a mix of two of his interests: good smoked meat and cocktails",
            picture: harel
        },
        {
            name: "Gilad Feder",
            role: "Software Engineer",
            interest: "Gilad enjoys esthetics a lot. He appreciates good architecture, design and good food. In his spare time he likes to work out.",
            picture: gilad
        },
        {
            name: "Yuval Krinberg",
            role: "Junior Software Engineer",
            interest:
                "Yuval enjoys picking flowers, drying them up and creating the most magnificent bouquets out of them",
            picture: yuval
        },
        {
            name: "Omer Anati",
            role: "Senior Software Engineer",
            interest:
                "Omer loves cooking and baking and once he finds a new challenge to make, it is a matter of minutes before he makes it himself",
            picture: omer
        },
        {
            name: "Yonatan Gelbard",
            role: "Software Engineer",
            interest:
                "Yonatan, besides software engineering, really likes Electrical Engineering and RF engineering.",
            picture: yonatan
        },
        {
            name: "Daniel Erlich",
            role: "Junior Software Engineer",
            interest:
                "Daniel came from the fashion industry, where he designed a lot of great shirts, hats, pants and more",
            picture: daniel
        },
        {
            name: "Roee Yosef",
            role: "Software Engineer",
            interest:
                "Roee is an everlasting Tel-Avivian, and you can probably spot him around the city streets if you pay enough attention",
            picture: roee
        }
    ],
    interested: {
        description:
            "We really like visual challenges. Ranging from displaying complex flows using different data models, all the way to how the end user understands our product.",
        items: [
            {
                img: micro,
                title: "Micro Interactions",
                description: "Ensuring every little detail is fun to use"
            },
            {
                img: visual,
                title: "Data Visualizations",
                description:
                    "Making sure the main datapoints are visualized well for the user"
            },
            {
                img: filter,
                title: "Data Models",
                description:
                    "To get that hard visualizations, we gotta find the interesting data first"
            }
        ]
    },
    challenges:
        "We're constantly challenged by receiving many feature requests everyday. In the end, we're the only team displaying stuff to the merchant, and they're always keeping us challenged :)",
    interviewProcess: [
        {
            img: phone,
            name: "Phone Call",
            nameDescription: "Ring Ring",
            description:
                "We'll give you a call, introduce ourselves and chat a bit. We might ask a few technical questions just to know we're all on the same page."
        },
        {
            img: homework,
            name: "Homework",
            nameDescription: "Just to prove us you're worthy",
            description:
                "We'll ask you to prepare homework over a few days with new technologies, just to see how you're holding up. They're not very hard, as we want everyone to express themselves and see which problem-solving directions are taken"
        },
        {
            img: interview,
            name: "Technical Interview",
            nameDescription: "You'll get to see our beautiful offices",
            description:
                "We'll ask you to alter some parts of your homework to create a new feature. We know it's very stressful, so we're not asking anything over-the-top :)"
        },
        {
            img: executive,
            name: "Executive Interview",
            nameDescription: "Almost there...",
            description:
                "One of our executives (VP R&D and sometimes CTO) will ask you questions to see your cultural fit and technical domain(s) across teams."
        },
        {
            img: hr,
            name: "HR Interview",
            nameDescription: "Bingo!",
            description:
                "The HR department will ask questions regarding your history and will provide detail regarding terms and benefits."
        }
    ],
    email: {
        address: "product.engineering@forter.com",
        subject: "Applying to Product Engineering",
        body: [
            "Hey Team!",
            "I saw your team profile on forter.dev and I thought I could be a good fit.",
            "My main technologies are X, Y.",
            "In my spare time I like to do Z."
        ]
    }
};

export default productEngineering;
